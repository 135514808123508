import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import SpecialOfferBanner from './special-offer'

export const ScrollingBanner = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay({ playOnInit: true, delay: 10000, stopOnMouseEnter: true, stopOnInteraction: false }),
  ])
  const { asPath } = useRouter()

  const isAdmin = asPath.includes('admin')
  // eslint-disable-next-line no-restricted-syntax
  const isJanSpecial = new Date() >= new Date('2025-01-20T00:00:00Z') && new Date() < new Date('2025-01-26T00:00:00Z')

  const [slides, setSlides] = useState<React.ReactNode[]>([])

  useEffect(() => {
    // eslint-disable-next-line react/jsx-key
    setSlides([!isAdmin && isJanSpecial && <SpecialOfferBanner />])
  }, [isJanSpecial])

  if (slides.length === 0) return null

  return (
    <div className="overflow-hidden" ref={emblaRef}>
      <div className="flex">
        {slides.map((slide, index) => (
          <div key={index} className="flex-[0_0_100%]">
            {slide}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScrollingBanner
