import * as UI from '@/ui'

export const SpecialOfferBanner = () => {
  return (
    <div className="bg-selphGrey-900 px-2 py-2 text-center">
      <span className="text-white sm:text-lg font-semiBold">
        <span className="text-hotPink-500">January Special!</span> Three month supply of vegan-friendly vitamin D with
        every order from 20<sup>th</sup> - 26<sup>th</sup> of January.
      </span>
      <UI.Link to="specialOfferTerms">
        <span className="text-white text-sm whitespace-nowrap"> Terms apply.</span>
      </UI.Link>
    </div>
  )
}

export default SpecialOfferBanner
